<template>
  <ui-link
    :link="{ to: '/' }"
    :class="width > 1199 && 'ui-logo-vrp_active-hover'"
    class="ui-logo-vrp"
    data-testid="element_000329"
  >
    <img
      :src="defaultLogo.permalink"
      :alt="defaultLogo.alt || 'VR Smash'"
      class="ui-logo-vrp_default"
    >
    <img
      :src="hoverLogo.permalink"
      :alt="hoverLogo.alt || 'VR Smash'"
      class="ui-logo-vrp_hover"
    >
  </ui-link>
</template>

<script lang="ts" setup>
import { useGlobalStore } from '~/stores'

interface Props {
  isFooter?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isFooter: false,
})

const { isFooter } = toRefs(props)

const { width } = useSSRWindowSize()
const globalStore = useGlobalStore()

const logosUrl = computed(() => {
  return {
    default: globalStore.siteSettings?.headerLogoLight || {},
    color: globalStore.siteSettings?.headerLogoColor || {},
    footer: globalStore.siteSettings?.footerLogo || {},
  }
})

const defaultLogo = computed(() => {
  if (isFooter.value) {
    return logosUrl.value.footer
  }
  return logosUrl.value.default
})

const hoverLogo = computed(() => {
  if (logosUrl.value.color) {
    return logosUrl.value.color
  }
  return logosUrl.value.default
})
</script>

<style lang="scss">
.ui-logo-vrp {
  width: 106rem;
  height: 20rem;
  position: relative;
  display: block;

  @include bp-tabletXS('min') {
    width: 156rem;
    height: 28rem;
  }

  @include bp-desktopXS('min') {
    width: 174rem;
    height: 32rem;
  }

  &_default,
  &_hover {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    transition: 0.3s;
  }

  &_default {
    opacity: 1;
  }

  &_hover {
    opacity: 0;
  }

  &_active-hover {
    &:hover {
      .ui-logo-vrp_default {
        opacity: 0;
      }

      .ui-logo-vrp_hover {
        opacity: 1;
      }
    }
  }

  &:active {
    .ui-logo-vrp_default {
      opacity: 0;
    }

    .ui-logo-vrp_hover {
      opacity: 1;
    }
  }
}
</style>
